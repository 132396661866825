const MARKETS = {
  usa: {
    countryCode: 'US',
    locales: ['en-US', 'es-US'],
  },
  lithuania: {
    countryCode: 'LT',
    locales: ['lt', 'ru-LT'],
  },
  latvia: {
    countryCode: 'LV',
    locales: ['lv', 'ru-LV'],
  },
  poland: {
    countryCode: 'PL',
    locales: ['pl'],
  },
  romania: {
    countryCode: 'RO',
    locales: ['ro'],
  },
  hungary: {
    countryCode: 'HU',
    locales: ['hu'],
  },
  france: {
    countryCode: 'FR',
    locales: ['fr'],
  },
  czechia: {
    countryCode: 'CZ',
    locales: ['cs'],
  },
  croatia: {
    countryCode: 'HR',
    locales: ['hr'],
  },
  bulgaria: {
    countryCode: 'BG',
    locales: ['bg'],
  },
  italy: {
    countryCode: 'IT',
    locales: ['it'],
  },
  estonia: {
    countryCode: 'EE',
    locales: ['et', 'ru-EE'],
  },
  ukraine: {
    countryCode: 'ua',
    locales: ['uk', 'ru-UA'],
  },
  belgium: {
    countryCode: 'be',
    locales: ['nl-BE', 'fr-BE'],
  },
  finland: {
    countryCode: 'fi',
    locales: ['fi', 'sv-FI'],
  },
  switzerland: {
    countryCode: 'ch',
    locales: ['de-CH', 'fr-CH', 'it-CH'],
  },
  mexico: {
    countryCode: 'mx',
    locales: ['es-MX'],
  },
  spain: {
    countryCode: 'es',
    locales: ['es'],
  },
  portugal: {
    countryCode: 'pt',
    locales: ['pt'],
  },
  greece: {
    countryCode: 'gr',
    locales: ['el-GR'],
  },
  southAfrica: {
    countryCode: 'za',
    locales: ['en-ZA'],
  },
  uae: {
    countryCode: 'ae',
    locales: ['en-AE'],
  },
  europe: {
    countryCode: 'eu',
    locales: ['en'],
  },
  britain: {
    countryCode: 'gb',
    locales: ['en-GB'],
  },
  australia: {
    countryCode: 'au',
    locales: ['en-AU'],
  },
  sweden: {
    countryCode: 'se',
    locales: ['sv'],
  },
  slovakia: {
    countryCode: 'sk',
    locales: ['sk'],
  },
  slovenia: {
    countryCode: 'si',
    locales: ['sl'],
  },
  serbia: {
    countryCode: 'rs',
    locales: ['sr'],
  },
  germany: {
    countryCode: 'de',
    locales: ['de'],
  },
  denmark: {
    countryCode: 'dk',
    locales: ['da'],
  },
};

const LOCALES = Object.values(MARKETS).flatMap((market) => market.locales);

module.exports = { MARKETS, LOCALES };
