import type { CountryWatermarkImageMap } from 'types/settings';

const DEFAULT_WATERMARK_OFFSET = 5;
const MAX_WATERMARK_OFFSET = 50;

const SETTINGS_LOCALES = [
  'en',
  'bg',
  'cs',
  'da',
  'de',
  'el-GR',
  'es',
  'es-MX',
  'et',
  'fi',
  'fr',
  'hu',
  'hr',
  'it',
  'lt',
  'lv',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
  'uk',
];

const IMAGES = SETTINGS_LOCALES.reduce<CountryWatermarkImageMap>(
  (acc, current) => ({
    ...acc,
    [current]: {
      title: `Watermark (${current.toUpperCase()})`,
      src: `/images/watermarks/watermark-${current}.png`,
    },
  }),
  {},
);

export { DEFAULT_WATERMARK_OFFSET, MAX_WATERMARK_OFFSET, IMAGES, SETTINGS_LOCALES };
