import { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { DEFAULT_WATERMARK_OFFSET, IMAGES } from 'constants/watermark';
import { WatermarkPosition } from 'types/settings';

type Settings = {
  position: WatermarkPosition;
  selectedLocale: string;
  offsetScale: number;
};

type SettingsContextValue = Settings & {
  setSetting: (setting: Settings) => void;
  resetSettings: () => void;
};

const SettingsContext = createContext<SettingsContextValue | undefined>(undefined);

const SettingsProvider = ({ ...otherProps }) => {
  const { i18n } = useTranslation();

  const defaultSettings = useMemo<Settings>(
    () => ({
      position: WatermarkPosition.TopLeft,
      offsetScale: DEFAULT_WATERMARK_OFFSET,
      selectedLocale: i18n.resolvedLanguage ?? 'en',
    }),
    [i18n.resolvedLanguage],
  );

  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const watermark = IMAGES[settings.selectedLocale] ?? IMAGES.en;

  const value = useMemo(
    () => ({
      ...settings,
      setSetting: (setting: Settings) => setSettings({ ...settings, ...setting }),
      resetSettings: () => setSettings(defaultSettings),
      watermark,
    }),
    [settings, defaultSettings, watermark],
  );

  return <SettingsContext.Provider value={value} {...otherProps} />;
};

const useSettings = () => {
  const settingsContext = useContext(SettingsContext);

  if (settingsContext === undefined) {
    throw new Error('useSettings must be inside a SettingsProvider');
  }

  return settingsContext;
};

export { useSettings, SettingsProvider };
